<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="140px"
    >
      <el-form-item label="部门" prop="departmentId">
        <el-select
          v-model="dataForm.departmentId"
          clearable
          filterable
          :disabled="dataForm.status != 0"
          style="width: 100%"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发现人" prop="fxr">
        <el-select
          v-model="dataForm.fxr"
          clearable
          filterable
          :disabled="dataForm.status != 0"
          style="width: 100%"
        >
          <el-option
            v-for="item in employeeList"
            :key="item.id"
            :label="item.label"
            :value="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="填报人" prop="fillEid">
        <el-select
          @change="selectFill"
          v-model="dataForm.fillEid"
          clearable
          filterable
          :disabled="dataForm.status != 0"
          style="width: 100%"
        >
          <el-option
            v-for="item in employeeList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="隐患发现时间" prop="findDate">
        <el-date-picker
          :disabled="dataForm.status != 0"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="dataForm.findDate"
          style="width: 100%"
          type="date"
          placeholder="选择隐患发现时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="隐患等级" prop="type">
        <el-select
          :disabled="dataForm.status != 0"
          v-model="dataForm.type"
          clearable
          filterable
          style="width: 100%"
        >
          <el-option
            v-for="item in ['一般隐患', '较大隐患', '重大隐患']"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="隐患描述" prop="describeInfo">
        <editor ref="yhmsEditor" v-model="dataForm.describeInfo"></editor>
      </el-form-item>
      <el-form-item
        v-if="dataForm.status > 0"
        label="部门审核意见"
        prop="bmshyj"
      >
        <el-input
          :disabled="dataForm.status != 0"
          v-model="dataForm.bmshyj"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="dataForm.status > 0"
        label="质安管理员审核意见"
        prop="zabglyshyj"
      >
        <el-input
          :disabled="dataForm.status != 0"
          v-model="dataForm.zabglyshyj"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="dataForm.status > 0"
        label="质安负责人审核意见"
        prop="zabfzrshyj"
      >
        <el-input
          :disabled="dataForm.status != 0"
          v-model="dataForm.zabfzrshyj"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.status > 0" label="验收意见" prop="ysyj">
        <el-input
          :disabled="dataForm.status != 0"
          v-model="dataForm.ysyj"
        ></el-input>
      </el-form-item>

      <div
        v-if="
          dataForm.status == 3 ||
          dataForm.status == 6 ||
          dataForm.status == 8 ||
          dataForm.status == 9 ||
          dataForm.status == 10 ||
          dataForm.status == 11
        "
      >
        <el-form-item label="整改措施" prop="reformMeasures">
          <editor ref="zgcsEditor" v-model="dataForm.reformMeasures"></editor>
        </el-form-item>
        <el-form-item label="整改时限" prop="reformTimeLimit">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="dataForm.reformTimeLimit"
            style="width: 100%"
            type="date"
            placeholder="选择整改时限"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="整改负责人" prop="reformResponsibilityEid">
          <el-select
            @change="selectReformResponsibility"
            v-model="dataForm.reformResponsibilityEid"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in employeeList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="验收标准" prop="reformCheckStandard">
          <el-input
            type="textarea"
            rows="6"
            v-model="dataForm.reformCheckStandard"
            placeholder="整改验收标准"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div v-if="dataForm.id">
      <p>日志记录</p>
      <el-input
        type="textarea"
        v-model="dataForm.log"
        disabled
        rows="6"
      ></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="flag != 1" type="primary" @click="dataFormSubmit(flag)"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import Editor from "@/components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      visible: false,
      departmentList: [], // 部门列表
      employeeList: [], // 员工列表
      flag: 0,
      dataForm: {
        id: 0,
        departmentId: "",
        fillUid: "",
        fillEid: "",
        fillName: "",
        findDate: "",
        fxr: "",
        describeInfo: "",
        status: "",
        log: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
        reformMeasures: "",
        reformTimeLimit: "",
        reformResponsibilityEid: "",
        reformResponsibilityName: "",
        reformCheckStandard: "",
        isUrge: "",
        type: "",
        bmshyj: "",
        zabglyshyj: "",
        zabfzrshyj: "",
        ysyj: "",
      },
      dataRule: {
        departmentId: [
          { required: true, message: "部门不能为空", trigger: "blur" },
        ],
        fxr: [{ required: true, message: "发现人不能为空", trigger: "blur" }],
        type: [
          { required: true, message: "隐患等级不能为空", trigger: "blur" },
        ],
        findDate: [
          { required: true, message: "发现时间不能为空", trigger: "blur" },
        ],
        fillEid: [
          { required: true, message: "填报人不能为空", trigger: "blur" },
        ],
        describeInfo: [
          { required: true, message: "隐患描述不能为空", trigger: "blur" },
        ],
        reformMeasures: [
          { required: true, message: "整改措施不能为空", trigger: "blur" },
        ],
        reformTimeLimit: [
          { required: true, message: "整改时限不能为空", trigger: "blur" },
        ],
        reformResponsibilityEid: [
          { required: true, message: "整改负责人不能为空", trigger: "blur" },
        ],
        reformCheckStandard: [
          { required: true, message: "验收标准不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ page: 1, limit: 99999 }).then((res) => {
        if (!res.data) return;
        this.departmentList = res.data.list || [];
      });
    },
    // 获取员工列表
    getEleployeeList() {
      this.$http.employee.list({ page: 1, limit: 99999 }).then((res) => {
        this.employeeList = [];
        if (!res.data) return;
        if (res.data.list && res.data.list.length > 0) {
          res.data.list.forEach((item) => {
            this.employeeList.push({
              id: item.id,
              label: item.name + "(" + item.departmentName + ")",
              name: item.name,
              uid: item.uid,
            });
          });
        }
      });
    },
    // 选中整改负责人
    selectReformResponsibility() {
      this.employeeList.forEach((item) => {
        if (item.id == this.dataForm.reformResponsibilityEid) {
          this.dataForm.reformResponsibilityName = item.name;
        }
      });
    },
    // 选中填报人
    selectFill() {
      this.employeeList.forEach((item) => {
        if (item.id == this.dataForm.fillEid) {
          this.dataForm.fillUid = item.uid;
          this.dataForm.fillName = item.name;
        }
      });
    },
    // 初始化  flag 不传：新增/修改 1：查看  2：填写
    init(id, flag) {
      this.flag = flag;
      this.getDepartmentList();
      this.getEleployeeList();
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();

        if (this.dataForm.id) {
          this.$http.hidden.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              this.$refs.yhmsEditor.destroy();
              this.$refs.yhmsEditor.init(this.dataForm.describeInfo);
              if (
                this.dataForm.status == 3 ||
                this.dataForm.status == 6 ||
                this.dataForm.status == 8 ||
                this.dataForm.status == 9 ||
                this.dataForm.status == 10 ||
                this.dataForm.status == 11
              ) {
                this.$nextTick(() => {
                  console.log(this.$refs.zgcsEditor);
                  this.$refs.zgcsEditor.destroy();
                  this.$refs.zgcsEditor.init(this.dataForm.reformMeasures);
                });
              }
            }
          });
        } else {
          this.dataForm = {
            id: 0,
            departmentId: "",
            fillUid: "",
            fillEid: "",
            fillName: "",
            findDate: "",
            fxr: "",
            describeInfo: "",
            status: "",
            log: "",
            createDate: "",
            updateDate: "",
            isDeleted: "",
            reformMeasures: "",
            reformTimeLimit: "",
            reformResponsibilityEid: "",
            reformResponsibilityName: "",
            reformCheckStandard: "",
            isUrge: "",
            type: "",
            bmshyj: "",
            zabglyshyj: "",
            zabfzrshyj: "",
            ysyj: "",
          };
          this.$refs.yhmsEditor.destroy();
          this.$refs.yhmsEditor.init(this.dataForm.describeInfo);
        }
      });
    },
    // flag 2:提交整改
    dataFormSubmit(flag) {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            if (flag == 2) {
              // 填写整改方案
              this.$http.hidden.write(requestDate).then((data) => {
                if (data && data.code === 0) {
                  this.$message({
                    message: "填写成功",
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      this.visible = false;
                      this.$emit("refreshDataList");
                    },
                  });
                } else {
                  this.$message.error(data.msg);
                }
              });
            } else {
              // 修改
              this.$http.hidden.update(requestDate).then((data) => {
                if (data && data.code === 0) {
                  this.$message({
                    message: "修改操作成功",
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      this.visible = false;
                      this.$emit("refreshDataList");
                    },
                  });
                } else {
                  this.$message.error(data.msg);
                }
              });
            }
          } else {
            // 新增
            requestDate.id = "";
            this.$http.hidden.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
